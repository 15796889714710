const $ = require('jquery');

import { $dataMetaSchema } from 'ajv';
import datetimepicker from 'jquery-datetimepicker';
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';

const CrossRetailing = (() => {

	// 変数宣言
	const pointSP = window.matchMedia('screen and (max-width: 767px)');
	let scrollY;

	// このJSのinit
	function init() {
		slideInbyScroll();
		smoothScroll();
		menuTrigger();

		menuLocalTrigger();

		modal();

		selectCheckbox();
		switchPassword();
		dateTimePicker();

		inputTOConfirmForm();

		modalCsvConfirm();
		modalCsvCancel();

		displaySelectedFileName();

		bookmark();
		commentList();
	}

	// 関数 ------------------------------------
	function fixedBG() {
		scrollY = $(window).scrollTop();
		$('body').addClass('is-fixed').css('top', -scrollY);
	}

	function fixedBGReset() {
		$('body').removeClass('is-fixed').removeAttr('style');
		$('html, body').scrollTop(scrollY);
	}

	function smoothScroll() {
		$('a[href^="#"]').on('click.smoothScroll', (event) => {
			const speed = 1200;// ミリ秒
			const href = $(event.currentTarget).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top;
			$('html, body').animate({ scrollTop: position }, speed, 'swing');
			return false;
		});
	}

	function slideInbyScroll() {
		const scrlBtn = $('.l-pagetop' + '.is-fixed');
		const point = pointSP.matches ? 667 : 768;

		$(window).on('scroll', () => {
			scrollY = $(window).scrollTop();
			if(scrollY >= point){
				scrlBtn.addClass('is-visible')
			}else if(scrollY < point ) {
				scrlBtn.removeClass('is-visible')
			}
		});
	}

	function menuTrigger() {
		const $trigger = $('.js-menu');
		const $nav = $('.l-header__nav');

		$trigger.on('click', () => {
			if($trigger.filter('.is-active').length) {
				$trigger.removeClass('is-active');
				$nav.removeClass('is-active');

				fixedBGReset();
			} else {
				$trigger.addClass('is-active');
				$nav.addClass('is-active');

				fixedBG();
			}
		});

		function checkBreakPoint(pointSP) {
			if (!pointSP.matches) {
				$trigger.removeClass('is-active');
				$nav.removeClass('is-active');
				fixedBGReset();
			}
		}

		pointSP.addListener(checkBreakPoint);
		// checkBreakPoint(pointSP);
	}

	function menuLocalTrigger() {
		const $trigger = $('.js-menu-local');
		const $nav = $('.l-nav-local');

		$trigger.on('click', () => {
			if($trigger.filter('.is-active').length) {
				$trigger.removeClass('is-active');
				$nav.removeClass('is-active');

				fixedBGReset();
			} else {
				$trigger.addClass('is-active');
				$nav.addClass('is-active');

				fixedBG();
			}
		});

		$('.l-nav-local__close').on('click', () => {
			$trigger.removeClass('is-active');
			$nav.removeClass('is-active');

			fixedBGReset();
		});

		function checkBreakPoint(pointSP) {
			if (!pointSP.matches) {
				$trigger.removeClass('is-active');
				$nav.removeClass('is-active');
				fixedBGReset();
			}
		}

		pointSP.addListener(checkBreakPoint);
		// checkBreakPoint(pointSP);
	}

	function modal() {
		const $trigger = $('[aria-controls*="modal"]');

		$trigger.off('click.smoothScroll').on('click', (event) => {
			const $self = $(event.currentTarget);
			const expanded = $self.attr('aria-expanded');
			const $target = $('#' + $self.attr('aria-controls'));
			const $other = $target.find('[aria-controls*="modal"]');

			if (expanded === 'false') {
				$self.attr('aria-expanded', true).addClass('is-active');
				$other.attr('aria-expanded', true);
				$target.attr('aria-hidden', false).addClass('is-active');

				fixedBG();
			} else {
				$trigger.attr('aria-expanded', false).removeClass('is-active');
				$target.attr('aria-hidden', true).removeClass('is-active');

				fixedBGReset();
			}

			return false;
		});
	}

	function selectCheckbox() {
		const $select = $('.js-select'),
		      $all = $('.js-select-all'),
		      $message = $('.c-table__message'),
		      $messageNumber = $message.find('.c-table__message-number');

		selectCheck();

		function selectCheck() {
			$select.each((index, element) => {
				const $self = $(element);

				$messageNumber.text($select.filter(':checked').length);

				if ($self.prop('checked') == true) {
					$self.closest('tr').addClass('is-checked');
				} else {
					$self.closest('tr').removeClass('is-checked');
				}

			});

			if ($select.filter(':checked').length > 0) {
				$message.addClass('is-active');
			} else {
				$message.removeClass('is-active');
			}
		}

		$select.on('click', (event) => {
			const $self = $(event.currentTarget);

			$self.closest('tr').toggleClass('is-checked');
			$messageNumber.text($select.filter(':checked').length);

			if ($select.filter(':checked').length == $select.length) {
				$all.prop('checked', true);
				$message.addClass('is-active is-checked-all');
			} else if ($select.filter(':checked').length == 0) {
				$message.removeClass('is-active');
			} else {
				$all.prop('checked', false);
				$message.addClass('is-active').removeClass('is-checked-all');
			}
		});

		$all.on('click', (event) => {
			$select.prop('checked', event.currentTarget.checked);

			$message.toggleClass('is-checked-all');

			selectCheck();
		});
	}

	function switchPassword() {
		const $target = $('.js-switch-password');

		$target.on('change', (event) => {
			const $self = $(event.currentTarget),
			      $password = $self.find('.c-input-text'),
			      $switch = $self.find('.c-input-status__switch');

			if ($switch.prop('checked')) {
				$password.attr('type', 'text');
			} else {
				$password.attr('type', 'password');
			}
		});
	}

	function dateTimePicker() {
		const $dateTimePicker = $('.js-datetimepicker');
		const $datePicker = $('.js-datepicker');

		// $.datetimepicker.setLocale('ja');
		$.datetimepicker.setLocale('ja');
		$dateTimePicker.datetimepicker();

		$datePicker.datetimepicker({
			timepicker: false,
		});
	}

	function inputTOConfirmForm() {
		const $inputId = $('.js-id-to-confirm');
		const $inputName = $('.js-name-to-confirm');
		const $inputNickname = $('.js-nickname-to-confirm');
		const $inputMail = $('.js-mail-to-confirm');
		const $inputPhone = $('.js-phone-to-confirm');
		const $inputPass = $('.js-pass-to-confirm');
		const $target = $('.js-show-confirm');

		const $dispId = $('.js-id-display');
		const $dispName = $('.js-name-display');
		const $dispNickname = $('.js-nickname-display');
		const $dispMail = $('.js-mail-display');
		const $dispPhone = $('.js-phone-display');
		const $dispPass = $('.js-pass-display');
		const $dispSendmail = $('.js-sendmail-display');

		const $dataId = $('.js-id-confirm');
		const $dataName = $('.js-name-confirm');
		const $dataNickname = $('.js-nickname-confirm');
		const $dataMail = $('.js-mail-confirm');
		const $dataPhone = $('.js-phone-confirm');
		const $dataPass = $('.js-pass-confirm');
		const $dataSendmail = $('.js-sendmail-confirm');

		const $clear = $('.js-clear-confirm');

		$target.on('click', () => {
			let $inputSendmail = $("input[name='sendMail']:checked").val();
			let $pass = '●'.repeat($inputPass.val().length);

			$dispId.append($inputId.val());
			$dispName.append($inputName.val());
			$dispNickname.append($inputNickname.val());
			$dispMail.append($inputMail.val());
			$dispPhone.append($inputPhone.val());
			$dispPass.append($pass);
			$dispSendmail.append($inputSendmail);

			$dataId.val($inputId.val());
			$dataName.val($inputName.val());
			$dataNickname.val($inputNickname.val());
			$dataMail.val($inputMail.val());
			$dataPhone.val($inputPhone.val());
			$dataPass.val($inputPass.val());
			$dataSendmail.val($inputSendmail);
		})

		$clear.on('click', () => {
			$dispId.empty();
			$dispName.empty();
			$dispNickname.empty();
			$dispMail.empty();
			$dispPhone.empty();
			$dispPass.empty();
			$dispSendmail.empty();
		});
	}

	function modalCsvConfirm() {
		const $inputFile = $('#csvUpload');

		$inputFile.on('change', (event) => {

			const $self = $(event.currentTarget);
			$self.attr({
				'aria-expanded': false,
				'aria-controls': 'modalFileConfirm'
			});
			const expanded = $self.attr('aria-expanded');
			const $target = $('#' + $self.attr('aria-controls'));
			const $other = $target.find('[aria-controls*="modal"]');
			const $display = $('.js-show-filename');
			let $filename = $self.prop('files')[0].name;

			if (expanded === 'false') {
				$self.attr('aria-expanded', true).addClass('is-active');
				$other.attr('aria-expanded', true);
				$target.attr('aria-hidden', false).addClass('is-active');
				$display.append($filename);

				console.log(expanded);
				fixedBG();
			}

			return false;
		});


	}

	function modalCsvCancel() {
		const $inputFile = $('#csvUpload');
		const $trigger = $('.js-remove-file');
		const $display = $('.js-show-filename');

		$trigger.on('click', (event) => {
			const expanded = $inputFile.attr('aria-expanded');

			if (expanded === 'true') {
				$inputFile.removeAttr('aria-expanded');
				$inputFile.removeAttr('aria-controls');
				$display.empty();
				$inputFile.val('');
			}
		})
	}

	function displaySelectedFileName() {
		let $inputFile = [
			$('.js-file-select1 input'),
			$('.js-file-select2 input'),
			$('.js-file-select3 input')
		]
		let $btn = [
			$('.js-file-select1 label'),
			$('.js-file-select2 label'),
			$('.js-file-select3 label')
		]
		let $display = [
			$('.js-file-select1 span'),
			$('.js-file-select2 span'),
			$('.js-file-select3 span')
		]
		let $self = [];
		let $filename = [];

		for (let i = 0; i < $inputFile.length; i++) {
			$inputFile[i].on('change', (event) => {
				$self[i] = $(event.currentTarget);
				$filename[i] = $self[i].prop('files')[0].name;
				$display[i].text($filename[i]);

				if(!$self[i].prop('files')[0]) {
					$btn[i].removeClass('-border');
					$btn[i].addClass('-default');
					$btn[i].text('ファイルを選択')
					$display[i].removeClass('-selected');
				}else{
					$btn[i].removeClass('-default');
					$btn[i].addClass('-border');
					$btn[i].text('ファイルを再選択')
					$display[i].addClass('-selected');
				}
			});
		}
	}

	function bookmark() {
		if ($('#js-toggle-bookmark').length) {
			$('#js-toggle-bookmark').on('click', function(){
				const btn = $(this);
				$.ajax({
					type: 'POST',
					url: '/api/toggle_bookmark',
					data: {
						'content_id' : $(this).data('content-id')
					},
					dataType: 'json'
				}).done(function(json){
					if (json.result === 'created') {
						btn.text('ブックマーク削除');
					} else {
						btn.text('ブックマーク登録');
					}
				}).fail(function(json){
					console.log(json.result);
				});
			});
		}
	}

	function commentList() {
		const $target = $('#js-comment-list');

		if ($target.length) {
			$(document).on('click', '.js-post-reply', function(){
				const self = $(this);
				$.ajax({
					type: 'POST',
					url: '/api/post_reply',
					data: {
						'content_id' : $(this).data('content-id'),
						'parent_id' : $(this).data('parent-id'),
						'comment_body' : $(this).closest('.js-reply-form').find('[name=comment_body]').val()
					},
					dataType: 'json'
				}).done(function(json){
					$('.js-show-reply-form').remove();
					self.closest('.js-reply-list').find('.reply:last').after(json.view);
					self.closest('.js-reply-form').remove();
					alert('返信しました');
				}).fail(function(json){
					console.log(json.result);
				});
			});

			$(document).on('click', '.js-toggle-replies', function(){
				$(this).closest('.js-reply-list').find('.reply:nth-child(n+2)').toggleClass('d-none');
				$(this).closest('.js-reply-list').find('.js-reply-form').toggleClass('d-none');
			});

			$(document).on('click', '.js-delete-comment', function(){
				if (confirm('このコメントを削除しますか？')) {
					$('#js-delete-id').val($(this).data('comment-id'));
					$('#js-delete-form').submit();
				}
			});

			$target.infiniteScroll({
				append: '.comment',
				prefill: true,
				path: function() {
					return '/api/get_comments/?content_id=' + this.$element.data('content-id') + '&page=' + this.pageIndex;
				},
				checkLastPage: '.comment',
				status: '.scroller-status',
				hideNav: '.pagination',
				history: false,
				debug: true,
			});
		}
	}
	// -----------------------------------------

	return {
		init : init
	};

})();

// このタイミングで実行
$(() => {
	CrossRetailing.init();
});
